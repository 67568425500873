<!-- Identity service / storage access request -->
<iframe
  id="identity"
  [src]="identityService.sanitizedIdentityServiceURL"
  frameborder="0"
  class="global__iframe"
  [style.display]="globalVars.requestingStorageAccess ? 'block' : 'none'"
></iframe>

<div *ngIf="currentPath !== '/' && currentPath !== '/landing' && currentPath !== '/fcc-check'">
  <mat-drawer-container>
    <mat-drawer #leftDrawer [mode]="'push'" [position]="'start'">
      <button mat-mini-fab class="closeMenuBtn leftToggle" (click)="leftDrawer.toggle()">
        <img [src]="icons.close" alt="" style="width: 20px" />
      </button>
      <div class="leftSideMenu">
        <app-left-side-menu></app-left-side-menu>
      </div>
    </mat-drawer>
    <mat-drawer #rightDrawer [mode]="'push'" [position]="'end'">
      <button mat-mini-fab class="closeMenuBtn rightToggle" (click)="rightDrawer.toggle()">
        <img [src]="icons.close" alt="" style="width: 20px" />
      </button>
      <br />
      <app-right-side-menu></app-right-side-menu>
    </mat-drawer>
    <mat-drawer-content>
      <div
        class="main global__container"
        [ngClass]="globalVars.userIsDragging ? 'unselectable' : ''"
        *ngIf="!globalVars.requestingStorageAccess"
      >
        <div class="leftSideMenu showLgOnly">
          <app-left-side-menu></app-left-side-menu>
        </div>
        <div class="routeContainer">
          <div class="hideLgOnlyFlex mobileNav">
            <button mat-icon-button (click)="leftDrawer.toggle()" class="leftMenu">
              <img [src]="icons.menu" alt="" style="width: 25px" />
            </button>
            <div class="logoContainer">
              <div class="logoSection">
                <img [src]="icons.logo" alt="" style="max-width: 195px; height: auto" />
              </div>
            </div>
            <button mat-icon-button (click)="rightDrawer.toggle()" class="rightMenu">
              <img [src]="icons.userMenu" alt="" style="width: 30px" />
            </button>
          </div>
          <router-outlet *ngIf="!globalVars.loadingInitialAppState"></router-outlet>
          <app-cricket-loader *ngIf="globalVars.loadingInitialAppState"></app-cricket-loader>
          <!--<simple-center-loader *ngIf="globalVars.loadingInitialAppState"></simple-center-loader>-->
        </div>
        <div class="rightSideMenu showLgOnly">
          <app-right-side-menu></app-right-side-menu>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
<div *ngIf="currentPath === '/' || currentPath === '/landing' || currentPath === '/fcc-check'">
  <router-outlet></router-outlet>
</div>
<!-- <div *ngIf="!locationEnabled" class="location-warning">
  <p>Location is not Enabled click to enable.</p>
  <button mat-button (click)="enableLocation()">Enable location</button>
</div> -->
