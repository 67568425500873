<div class="logoSection centerContainer">
  <img [src]="icons.logo" alt="" />
  <!--<span class="edition">Alpha</span>-->
</div>

<div class="menuItems">
  <div class="menuTitle">
    {{ "GENERAL" | translate }}
    <img [src]="icons.arrowRight" />
  </div>
  <button mat-button *ngFor="let item of menuItems" (click)="openMenuItem(item.title, item.route)">
    <div *ngIf="item.title === 'Notifications' && globalVars.unreadNotifications != 0; else notNotification">
      <img [src]="icons[item.icon]" alt="" />
      <span [matBadge]="globalVars.unreadNotifications > 99 ? '(99+)' : globalVars.unreadNotifications " matBadgeOverlap="false">
        {{ item.title | translate }}</span>
    </div>
    <ng-template #notNotification >
      <img [src]="icons[item.icon]" alt="" />
      {{ item.title | translate }}
    </ng-template>
  </button>
</div>

<div class="menuItems" *ngIf="moreVisible">
  <div class="menuTitle">
    {{ "More" | translate }}
    <img [src]="icons.arrowRight" />
  </div>
  <div *ngFor="let item of moreMenuItems">
    <button mat-button (click)="httpService.navigate(item.route || '../home')" *ngIf="item.type === 'internal'">
      <img [src]="icons[item.icon]" alt="" />
      {{ item.title | translate }}
    </button>
    <a mat-button [href]="item.route" target="_blank" *ngIf="item.type === 'external'" class="text-decoration-none">
      <img [src]="icons[item.icon]" alt="" />
      {{ item.title | translate }}
    </a>
    <a mat-button [href]="item.route" target="_blank" *ngIf="item.type === 'mail'" class="text-decoration-none">
      <img [src]="icons[item.icon]" alt="" />
      {{ item.title | translate }}
    </a>
  </div>
</div>

<div class="menuItems">
  <div class="menuTitle">
    {{ "Cricket" | translate }}
    <img [src]="icons.arrowRight" />
  </div>
  <button mat-button *ngFor="let item of subMenuItems" (click)="httpService.navigate(item.route || '../home')">
    <img [src]="icons[item.icon]" alt="" />
    {{ item.title | translate }}
  </button>
  <button mat-button (click)="httpService.launchLogoutFlow()">
    <img [src]="icons.logout" alt="" />
    {{ "Logout" | translate }}
  </button>
</div>

<div class="roundedContainer noShadow" (click)="createAdditionalProfile()">
  <div class="roundedContainerTitle active" style="height: 95px">
    <img [src]="APP_ICONS.createPlayer" alt="" />
    <span>{{ "createPlayerOther" | translate }}</span>
  </div>
</div>

<div class="roundedContainer noShadow" (click)="httpService.navigate('../teams/team')">
  <div class="roundedContainerTitle active">
    <img [src]="icons.createTeam" alt="" />
    <span>{{ "CreateaTeam" | translate }}</span>
  </div>
</div>

<!--<div class="roundedContainer noShadow" (click)="httpService.navigate('../grounds/ground')">-->
  <!--<div class="roundedContainerTitle active">-->
    <!--<img [src]="icons.createTeam" alt="" />-->
    <!--<span>{{ "Create_Grounds" | translate }}</span>-->
  <!--</div>-->
<!--</div>-->

<!--<div class="roundedContainer noShadow" (click)="httpService.navigate('../matches/invites/form')">-->
  <!--<div class="roundedContainerTitle active">-->
    <!--<img [src]="icons.createMatchInvite" alt="" />-->
    <!--<span>{{ "MatchesInfo.create_invite" | translate }}</span>-->
  <!--</div>-->
<!--</div>-->

<!--<div class="roundedContainer noShadow" (click)="httpService.navigate('../quizzes/form')">
  <div class="roundedContainerTitle active">
    <img [src]="icons.createTeam" alt="" />
    <span style="max-width: 90px">{{ "Create_a_Quiz" | translate }}</span>
  </div>
</div>-->
