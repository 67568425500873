import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { PlayerProfileCardComponent } from './player-profile-card/player-profile-card.component';
import { UserProfileCardComponent } from './user-profile-card/user-profile-card.component';
import { ProfileCardsComponent } from './profile-cards.component';
import { UserViewCardComponent } from './user-view-card/user-view-card.component';
import { UserProfilesModalComponent } from './user-profiles-modal/user-profiles-modal.component';
import { MatListModule } from '@angular/material/list';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { SimpleProfileCardComponent } from './simple-profile-card/simple-profile-card.component';
import { SharedDirectivesModule } from '../../shared-directives/shared-directives.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MultiProfileModalModule } from "../../profile/multi-profile-modal/multi-profile-modal.module";

@NgModule({
  declarations: [
    PlayerProfileCardComponent,
    UserProfileCardComponent,
    ProfileCardsComponent,
    UserViewCardComponent,
    UserProfilesModalComponent,
    SimpleProfileCardComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatSelectModule,
    MatMenuModule,
    TranslateModule,
    ClipboardModule,
    MatListModule,
    MatBottomSheetModule,
    MatTooltipModule,
    SharedDirectivesModule,
    MultiProfileModalModule
  ],
  exports: [ProfileCardsComponent, SimpleProfileCardComponent],
})
export class ProfileCardsModule {}
