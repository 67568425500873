import { Component } from '@angular/core';
import { ICONS } from '../../../lookups/icons.lookups';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../../theme/theme.service';
import { CacheServiceService } from '../../../services/common/cache-service.service';
import { DEFAULT_PAYLOAD, LANGUAGES, THEMES } from '../../../lookups/app.lookups';
import { HttpService, Payload } from '../../../services/common/http.service';
import { IdentityService } from '../../../services/deso/identity.service';
import { GlobalVarsService } from '../../../services/deso/global-vars.service';
import { PLAYER_PROFILES_APIS, TEAM_INVITE_APIS, USER_APIS } from '../../../lookups/apis.lookups';
import { ChangeDetectorService } from '../../../services/common/change-detector';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AdditionalProfilesModalComponent } from '../../common/profile-cards/additional-profiles-modal/additional-profiles-modal.component';
import { BackendApiService } from '../../../services/deso/backend-api.service';

@Component({
  selector: 'app-right-side-menu',
  templateUrl: './right-side-menu.component.html',
  styleUrls: ['./right-side-menu.component.scss'],
})
export class RightSideMenuComponent {
  loadingOpenInvites = false;
  invites: any[] = [];
  openInvitesCount: number = 0;
  currentScrollPage = 0;
  payload: Payload = DEFAULT_PAYLOAD;
  loading: boolean;
  user: any;
  icons: any = ICONS[this.themeService.getActiveTheme().icons];
  themes = THEMES;
  languages = LANGUAGES;

  constructor(
    public translate: TranslateService,
    private backendApi: BackendApiService,
    private themeService: ThemeService,
    private identityService: IdentityService,
    public httpService: HttpService,
    public globalVars: GlobalVarsService,
    private changeDetectorService: ChangeDetectorService,
    private cacheServiceService: CacheServiceService,
    private _bottomSheet: MatBottomSheet
  ) {
    this.user = this.cacheServiceService.getSessionData('user');
    this.changeDetectorService.watchUserInfoChanges().subscribe((value) => {
      this.user = this.cacheServiceService.getSessionData('user');
    });
    this.getLoggedInUserProfiles();
  }

  ngOnInit(): void {
    this.getInvitesReceivedOpen();
  }
  /**
   * CHANGE THE I18 translation
   * @param lang
   */
  public changeLanguage(lang): void {
    this.translate.setDefaultLang(lang);
    this.cacheServiceService.saveLocalStorage('Language', lang);
  }

  /**
   * CHange the theme
   * @param theme
   */
  public changeTheme(theme): void {
    this.themeService.setTheme(theme);
    this.cacheServiceService.saveLocalStorage('Theme', theme);
    this.icons = ICONS[this.themeService.getActiveTheme().icons];
  }

  public login(): void {
    this.globalVars.launchLoginFlow((res) => {
      console.log(res);
    });
  }

  private getLoggedInUserProfiles(): void {
    if (this.backendApi.LastLoggedInUserKey) {
      const owner = JSON.parse(localStorage.getItem(this.backendApi.LastLoggedInUserKey));
      this.loading = true;
      this.httpService.getRequest(USER_APIS.profiles + owner).subscribe(
        (profiles) => {
          if (profiles) {
            this.user.playerProfiles = profiles.playerProfiles;
            this.user.sponsorProfiles = profiles.sponsorProfiles;
            this.user.auxiliaryIndividualProfiles = profiles.auxiliaryIndividualProfiles;
            this.user.enableMultiProfiles = profiles.enableMultiProfiles;
          }
          this.user.owner = owner;
          this.cacheServiceService.setSessionData('user', this.user);
          this.changeDetectorService.emitUserInfoChanges();
          this.loading = false;
        },
        (error1) => {
          this.loading = false;
        }
      );
    }
  }

  getInvitesReceivedOpen(): void {
    if (this.payload.pagination.next === 0 && this.invites.length > 0) {
      return;
    }
    this.loadingOpenInvites = true;
    const URL = TEAM_INVITE_APIS.listOpenTo + '/' + this.user.PublicKeyBase58Check;
    this.httpService.getWithPagination(URL, this.payload).subscribe(
      (value: Payload) => {
        console.log(value);
        this.payload = value;
        this.openInvitesCount = value.pagination.total;
        this.currentScrollPage = this.payload.pagination.page;
        this.invites = this.invites.concat(this.payload.data || []);

        // this.markers =this.commonService.activeScrollMarkers(this.players, this.payload.pagination.perPage, 'bio');
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log(error);
        // this.httpService.alertError('Error While Get Invites Data', this);
      }
    );
  }
}
