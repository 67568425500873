<div class="d-flex flex-column align-items-center justify-content-center" [ngStyle]="{ height: getHeight() }">
  <div class="lds-default"
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div
    [ngClass]="{
      'text-grey5': textColor === 'gray',
      'text-black': textColor === 'black',
      'text-white': textColor === 'white',
      'text-center': true
    }"
  >
    <div class="text-grey-loading fs-24px">{{ titleLoadingText | translate }}</div>
    <div *ngIf="subtitleLoadingText">
      <div class="text-grey-loading fs-15px text-center">{{ subtitleLoadingText }}</div>
    </div>
  </div>
</div>
