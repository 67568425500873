<div [ngSwitch]="activeProfile" *ngIf="activeProfile && profile">
  <app-user-profile-card *ngSwitchCase="'user'"
                         [user]="user"
                         [icons]="icons"
                         [loading]="loading"
                         [followerCount]="followerCount"
                         [followingCount]="followingCount"
                         [foundersRewardPercent]="getFoundersRewardPercent() | number: '0.0-9' "
                         [themes]="themes"
                         [languages]="languages"
                         (changeLanguage)="changeLanguage($event)"
                         (changeTheme)="changeTheme($event)"
                         (switchProfile)="switchProfile($event)"
  >
  </app-user-profile-card>
  <app-player-profile-card *ngSwitchCase="'player'"
                           [loading]="loading"
                           [followerCount]="followerCount"
                           [playerProfile]="user.playerProfiles ? user.playerProfiles[0]: {}"
                           [followingCount]="followingCount"
                           [foundersRewardPercent]="getFoundersRewardPercent() | number: '0.0-9' "
                           [user]="user"
                           [icons]="icons"
                           [themes]="themes"
                           [languages]="languages"
                           (changeLanguage)="changeLanguage($event)"
                           (changeTheme)="changeTheme($event)"
                           (switchProfile)="switchProfile($event)"
  >
  </app-player-profile-card>
  <app-user-view-card *ngSwitchCase="'view'"
                           [user]="user"
                           [icons]="icons"
                           [themes]="themes"
                           [languages]="languages"
                           (changeLanguage)="changeLanguage($event)"
                           (changeTheme)="changeTheme($event)"
                           (switchProfile)="switchProfile($event)"
  >
  </app-user-view-card>
</div>
