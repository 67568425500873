import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/common/interceptors/auth-guard.service';
// import { FCCGuard } from './services/common/interceptors/fcc-guard.service';
import { NotAuthGuard } from './services/common/interceptors/not-auth-guard.service';
import { ProfileCheckGuard } from './services/common/interceptors/profile-check-guard.service';

class RouteNames {
  // /:username/following
  public static FOLLOWING = 'following';

  // /:username/followers
  public static FOLLOWERS = 'followers';

  public static BROWSE = 'browse';
  public static CREATORS = 'creators';
  public static BUY_DESO = 'buy-deso';
  public static WALLET = 'wallet';
  public static SETTINGS = 'settings';
  public static USER_PREFIX = 'u';
  public static INBOX_PREFIX = 'inbox';
  public static TRANSFER_CREATOR = 'transfer';
  public static PICK_A_COIN = 'select-creator-coin';
  public static BUY_CREATOR = '../deso/buy';
  public static SELL_CREATOR = 'sell';
  public static UPDATE_PROFILE = 'update-profile';
  public static NOTIFICATIONS = 'notifications';
  public static SIGN_UP = 'sign-up';
  public static NOT_FOUND = '404';
  public static POSTS = 'posts';
  public static SEND_DESO = 'send-deso';
  // TODO: how do I make this /posts/new?
  public static CREATE_POST = 'posts/new';
  public static TOS = 'terms-of-service';
  public static ADMIN = 'admin';
  public static GET_STARTER_DESO = 'get-starter-deso';
  public static LANDING = '/';
  public static DIAMONDS = 'diamonds';
  public static TRENDS = 'trends';
  public static REFERRALS = 'referrals';
  public static NFT = 'nft';
  public static TRANSFER_NFT_ACCEPT = 'accept-nft-transfer';
  public static VERIFY_EMAIL = 'verify-email';

  public static TUTORIAL = 'tutorial';
  public static CREATE_PROFILE = 'create-profile';
  public static INVEST = 'invest';
  public static SUPPLY_STATS = 'supply-stats';
  public static DAO = 'dao';
}
const routes: Routes = [
  {
    path: 'home',
    children: [{ path: '', loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule) }],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },

  {
    path: '',
    children: [
      { path: '', loadChildren: () => import('./modules/landing/landing.module').then((m) => m.LandingModule) },
    ],
    canActivate: [],
  },

  {
    path: 'landing',
    children: [
      { path: '', loadChildren: () => import('./modules/landing/landing.module').then((m) => m.LandingModule) },
    ],
    canActivate: [],
  },
  {
    path: 'no-mobile',
    children: [
      { path: '', loadChildren: () => import('./modules/no-mobile/no-mobile.module').then((m) => m.NoMobileModule) },
    ],
    canActivate: [],
  },

  {
    path: 'fcc-check',
    children: [
      { path: '', loadChildren: () => import('./modules/fcccheck/fcccheck.module').then((m) => m.FCCCheckModule) },
    ],
    canActivate: [AuthGuard],
  },

  {
    path: 'profile',
    children: [
      { path: '', loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule) },
    ],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },

  {
    path: 'teams',
    children: [{ path: '', loadChildren: () => import('./modules/teams/teams.module').then((m) => m.TeamsModule) }],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },
  {
    path: 'team-invites',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/team-invites/team-invites.module').then((m) => m.TeamInvitesModule),
      },
    ],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },

  {
    path: 'grounds',
    children: [{ path: '', loadChildren: () => import('./modules/grounds/grounds.module').then((m) => m.GroundsModule) }],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },

  {
    path: 'auth',
    children: [{ path: '', loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule) }],
    canActivate: [AuthGuard],
  },
  {
    path: 'update-profile',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/update-profile/update-profile.module').then((m) => m.UpdateProfileModule),
      },
    ],
    canActivate: [AuthGuard],
  },

  {
    path: 'deso',
    children: [
      { path: '', loadChildren: () => import('./modules/deso-fn/deso-fn.module').then((m) => m.DesoFnModule) },
    ],
    canActivate: [AuthGuard],
  },

  {
    path: 'tournaments',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/tournaments/tournaments.module').then((m) => m.TournamentsModule),
      },
    ],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },

  {
    path: 'matches',
    children: [
      { path: '', loadChildren: () => import('./modules/matches/matches.module').then((m) => m.MatchesModule) },
    ],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },

  {
    path: 'start-match',
    children: [
      { path: '', loadChildren: () => import('./modules/matches/start-match/start-match.module').then((m) => m.StartMatchModule) },
    ],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },

  {
    path: 'players',
    children: [
      { path: '', loadChildren: () => import('./modules/players/players.module').then((m) => m.PlayersModule) },
    ],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },

  {
    path: 'grounds',
    children: [
      { path: '', loadChildren: () => import('./modules/grounds/grounds.module').then((m) => m.GroundsModule) },
    ],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },

  {
    path: 'search',
    children: [{ path: '', loadChildren: () => import('./modules/search/search.module').then((m) => m.SearchModule) }],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },

  {
    path: 'inbox',
    children: [{ path: '', loadChildren: () => import('./modules/inbox/inbox.module').then((m) => m.InboxModule) }],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },
  {
    path: 'wip',
    children: [{ path: '', loadChildren: () => import('./modules/wip/wip.module').then((m) => m.WIPModule) }],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },
  {
    path: 'notifications',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/notifications-page/notifications-page.module').then((m) => m.NotificationsPageModule),
      },
    ],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },
  {
    path: 'wallet',
    children: [{ path: '', loadChildren: () => import('./modules/wallet/wallet.module').then((m) => m.WalletModule) }],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },

  {
    path: 'creators',
    children: [
      { path: '', loadChildren: () => import('./modules/creators/creators.module').then((m) => m.CreatorsModule) },
    ],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },

  {
    path: 'posts',
    children: [{ path: '', loadChildren: () => import('./modules/posts/posts.module').then((m) => m.PostsModule) }],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },

  {
    path: 'quizzes',
    children: [
      { path: '', loadChildren: () => import('./modules/quizzes/quizzes.module').then((m) => m.QuizzesModule) },
    ],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },
  {
    path: 'followers',
    children: [
      { path: '', loadChildren: () => import('./modules/creators/followers/followers.module').then((m) => m.FollowersModule) },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'other',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/other-profiles/other-profiles.module').then((m) => m.OtherProfilesModule),
      },
    ],
    canActivate: [AuthGuard, ProfileCheckGuard],
  },
  {
    path: '**',
    redirectTo: 'landing',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
class AppRoutingModule {
  static profilePath(username: string): string {
    return ['', RouteNames.USER_PREFIX, username].join('/');
  }

  static postPath(postHashHex: string): string {
    return ['', RouteNames.POSTS, postHashHex].join('/');
  }

  static nftPath(postHashHex: string): string {
    return ['', RouteNames.NFT, postHashHex].join('/');
  }
  static buyCreatorPath(username: string): string {
    return ['', RouteNames.USER_PREFIX, username, RouteNames.BUY_CREATOR].join('/');
  }
}
export { RouteNames, AppRoutingModule };
