import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-cricket-loader',
  templateUrl: './cricket-loader.component.html',
  styleUrls: ['./cricket-loader.component.scss']
})
export class CricketLoaderComponent implements OnInit {

  @Input() defaultLoader:boolean;
  @Input() fullScreen:boolean = true;

  constructor() { }
  ngOnInit(): void {}

}
