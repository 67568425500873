import { Injectable } from '@angular/core';
import * as _ from "lodash";
import { GlobalVarsService } from "../deso/global-vars.service";
import { BackendApiService } from "../deso/backend-api.service";
import { ICONS } from "../../lookups/icons.lookups";
import { ThemeService } from "../../theme/theme.service";
declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  icons:any = ICONS[this.themeService.getActiveTheme().icons];
   constructor(private globalVars: GlobalVarsService,
               private themeService: ThemeService,
               private backendApi: BackendApiService) {}



  activeScrollMarkers(items, perPage, aboutAttribute): any[] {
    const markers:any[] = [];
    const newItems = items.length >= perPage? items.slice(items.length - perPage) : items;
    newItems.forEach((item: any) => {
      if(item.baseGeoLocation){
        markers.push({
          latitude: item.baseGeoLocation.lat,
          longitude: item.baseGeoLocation.lng,
          about: item[aboutAttribute]
        });
      }
    });
    return markers;
  }


  getUserAvatar(avatar): string {
    let cacheBuster = "";
    if (
      this.globalVars?.loggedInUser && avatar === this.globalVars.loggedInUser?.PublicKeyBase58Check &&
      this.globalVars.profileUpdateTimestamp
    ) {
      cacheBuster = `&${this.globalVars.profileUpdateTimestamp}`;
    }

    let fallbackRoute = `fallback=${this.backendApi.GetDefaultProfilePictureURL(window.location.host)}`;
    const profPicURL = _.escape(
      this.backendApi.GetSingleProfilePictureURL(this.globalVars.localNode, avatar, fallbackRoute)
    );
    return (profPicURL + cacheBuster) ? profPicURL + cacheBuster : this.icons.photo;
  }


  dateSince(dateString: number): any {
    // get total seconds between the times
    let delta = Math.abs(new Date(dateString).getTime() - new Date().getTime()) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.ceil(delta / 60) % 60;

    return `${days ? days + 'd ' : ''} ${!days && hours ? hours + 'h' : ''} ${
      !days && !hours && minutes ? minutes + 'm' : ''
      }`;
  }


  measureDistance(point1: {lat: any, lng: any}, point2: {lat: any, lng: any} ): any {
    const from = new google.maps.LatLng(point1.lat, point1.lng);
    const to = new google.maps.LatLng(point2.lat, point2.lng);
    const distance:number = google.maps.geometry.spherical.computeDistanceBetween(from, to);
    return distance;
  }


  hasProfiles(user:any): boolean {
     if(
       (!user.playerProfiles || user.playerProfiles.length === 0) &&
       (!user.sponsorProfiles || user.sponsorProfiles.length === 0) &&
       (!user.auxiliaryIndividualProfiles || user.auxiliaryIndividualProfiles.length === 0)
     ){
       return false;
     } else{
       return true;
     }
  }
}
