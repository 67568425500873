import { Deso } from "../../assets/img/feather";

export const ICONS: any = {
  light: {
    logo: 'assets/icons/logo.png',
    settings: 'assets/icons/light/setting.svg',
    setting2: 'assets/icons/light/setting2.svg',
    search: 'assets/icons/light/search-normal.svg',
    login: 'assets/icons/light/login.svg',
    home: 'assets/icons/light/home-2.svg',
    people: 'assets/icons/light/creators.svg',
    crypto: 'assets/icons/light/deso.svg',
    wallet: 'assets/icons/light/wallet.svg',
    message: 'assets/icons/light/message.svg',
    chat: 'assets/icons/light/chat.svg',
    more: 'assets/icons/light/more.svg',
    matches: 'assets/icons/light/matches.svg',
    tournaments: 'assets/icons/light/tournaments.svg',
    teams: 'assets/icons/light/teams.svg',
    players: 'assets/icons/light/players.svg',
    grounds: 'assets/icons/light/grounds.svg',
    arrowRight: 'assets/icons/light/arrow-right.svg',
    createTeam: 'assets/icons/light/create-teams.svg',
    createTeam2: 'assets/icons/light/create-teams2.svg',
    createTournaments: 'assets/icons/light/create-tournaments.svg',
    notification: 'assets/icons/light/notification.svg',
    photo: 'assets/icons/light/photo.svg',
    photo2: 'assets/icons/light/photo2.svg',
    profile: 'assets/icons/light/profile.svg',
    profile2: 'assets/icons/light/profile2.svg',
    key: 'assets/icons/light/key.svg',
    copy: 'assets/icons/light/copy.svg',
    yourCoin: 'assets/icons/light/your-coin.svg',
    code: 'assets/icons/light/code.svg',
    image: 'assets/icons/light/image.svg',
    video: 'assets/icons/light/video.svg',
    charLength: 'assets/icons/light/char-length.svg',
    addCircle: 'assets/icons/light/add-circle.svg',
    theme: 'assets/icons/light/theme.svg',
    language: 'assets/icons/light/language.svg',
    menu: 'assets/icons/light/menu.svg',
    userMenu: 'assets/icons/light/user-menu.svg',
    close: 'assets/icons/light/close.svg',
    warning: 'assets/icons/light/warning.svg',
    switch: 'assets/icons/light/switch.svg',
    switch2: 'assets/icons/light/switch2.svg',
    gps: 'assets/icons/light/gps.svg',
    delete: 'assets/icons/light/delete.svg',
    playCircle: 'assets/icons/light/play-circle.svg',
    export: 'assets/icons/export.png',
    filter: 'assets/icons/actions/filter.svg',
    sort: 'assets/icons/actions/sort.svg',
    dao: 'assets/icons/light/more/dao.svg',
    help: 'assets/icons/light/more/help.svg',
    csBlog: 'assets/icons/light/more/cs-blog.svg',
    fcc: 'assets/icons/more/light/fcc.svg',
    createPlayer: 'assets/icons/light/create-player.svg',
    createPlayer2: 'assets/icons/light/create-player2.svg',
    logout: 'assets/icons/light/logout.svg',
    multiProfile: 'assets/icons/light/multiple-profile.svg',
    dateTime: 'assets/icons/light/dateTime.svg',
    edit: 'assets/icons/light/edit.png',
    thumbsUp: 'assets/icons/light/thumbs-up.png',
    thumbsDown: 'assets/icons/light/thumbs-down.png',
    applyAccept: 'assets/icons/light/apply-accept.png',
    crown: 'assets/icons/light/crown.svg',
    daodao: 'assets/icons/light/daodao.png',
    medium: 'assets/icons/light/medium.png',
    discord: 'assets/icons/light/discord.avg',
    twitter: 'assets/icons/light/twitter.png',
    deso: 'assets/icons/light/deso.svg',
    live: 'assets/icons/light/actions/live.svg',
    share: 'assets/icons/light/actions/share.svg',
    createMatchInvite: 'assets/icons/light/Create Match Invite.svg'
  },
  dark: {
    logo: 'assets/icons/light/logo.png',
    settings: 'assets/icons/setting.svg',
    setting2: 'assets/icons/setting2.svg',
    login: 'assets/icons/login.svg',
    search: 'assets/icons/search-normal.svg',
    home: 'assets/icons/home-2.svg',
    people: 'assets/icons/creators.svg',
    crypto: 'assets/icons/deso.svg',
    wallet: 'assets/icons/wallet.svg',
    message: 'assets/icons/message.svg',
    chat: 'assets/icons/chat.svg',
    more: 'assets/icons/more.svg',
    matches: 'assets/icons/matches.svg',
    tournaments: 'assets/icons/tournaments.svg',
    teams: 'assets/icons/teams.svg',
    players: 'assets/icons/players.svg',
    grounds: 'assets/icons/grounds.svg',
    arrowRight: 'assets/icons/arrow-right.svg',
    createTeam: 'assets/icons/create-teams.svg',
    createTeam2: 'assets/icons/create-teams2.svg',
    createTournaments: 'assets/icons/create-tournaments.svg',
    notification: 'assets/icons/notification.svg',
    photo: 'assets/icons/photo.svg',
    photo2: 'assets/icons/photo2.svg',
    profile: 'assets/icons/light/profile.svg',
    profile2: 'assets/icons/light/profile2.svg',
    key: 'assets/icons/light/key.svg',
    copy: 'assets/icons/light/copy.svg',
    yourCoin: 'assets/icons/your-coin.svg',
    code: 'assets/icons/code.svg',
    image: 'assets/icons/image.svg',
    video: 'assets/icons/video.svg',
    charLength: 'assets/icons/char-length.svg',
    addCircle: 'assets/icons/add-circle.svg',
    theme: 'assets/icons/theme.svg',
    language: 'assets/icons/language.svg',
    menu: 'assets/icons/menu.svg',
    userMenu: 'assets/icons/user-menu.svg',
    close: 'assets/icons/close.svg',
    warning: 'assets/icons/warning.svg',
    switch: 'assets/icons/switch.svg',
    switch2: 'assets/icons/switch2.svg',
    gps: 'assets/icons/gps.svg',
    delete: 'assets/icons/delete.svg',
    playCircle: 'assets/icons/play-circle.svg',
    export: 'assets/icons/export.png',
    filter: 'assets/icons/actions/filter.svg',
    sort: 'assets/icons/actions/sort.svg',
    dao: 'assets/icons/more/dao.svg',
    help: 'assets/icons/more/help.svg',
    csBlog: 'assets/icons/more/cs-blog.svg',
    fcc: 'assets/icons/more/fcc.svg',
    createPlayer: 'assets/icons/create-player.svg',
    createPlayer2: 'assets/icons/create-player2.svg',
    logout: 'assets/icons/logout.svg',
    multiProfile: 'assets/icons/multiple-profile.svg',
    dateTime: 'assets/icons/dateTime.svg',
    edit: 'assets/icons/edit.png',
    thumbsUp: 'assets/icons/light/thumbs-up.png',
    thumbsDown: 'assets/icons/light/thumbs-down.png',
    applyAccept: 'assets/icons/apply-accept.png',
    crown: 'assets/icons/light/crown.svg',
    daodao: 'assets/icons/daodao.png',
    medium: 'assets/icons/medium.png',
    discord: 'assets/icons/discord.svg',
    twitter: 'assets/icons/twitter.png',
    deso: '/assets/icons/deso.svg',
    live: 'assets/icons/actions/live.svg',
    share: 'assets/icons/actions/share.svg',
    createMatchInvite: 'assets/icons/Create Match Invite.svg'
  },
};
