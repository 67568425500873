import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './modules/layout/layout.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

// Modular Themes for DeSo by Carsen Klock @carsenk
import { ThemeModule } from './theme/theme.module';
import { Theme } from './theme/symbols';
import { BackendApiService } from './services/deso/backend-api.service';
import { GlobalVarsService } from './services/deso/global-vars.service';
import { BsModalService } from 'ngx-bootstrap/modal';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { IdentityService } from './services/deso/identity.service';
import { SimpleCenterLoaderComponent } from './modules/common/simple-center-loader/simple-center-loader.component';
import { THEMES } from './lookups/app.lookups';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { environment } from '../environments/environment';
import { MatNativeDateModule } from '@angular/material/core';
import { AvatarDirective } from './directives/avatar/avatar.directive';
import { SanitizeAndAutoLinkPipe } from '../lib/pipes/sanitize-and-auto-link-pipe';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/analytics';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { CricketLoaderModule } from "./modules/common/cricket-loader/cricket-loader.module";
import { DecimalPipe } from "@angular/common";

const config: SocketIoConfig = {
  url: environment.socketUrl, // socket server url;
  options: {
    transports: ['websocket'],
  },
};

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname,
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#F0F0F0',
      text: '#000',
    },
    button: {
      background: '#FF9F1D',
      text: '#fff',
    },
  },
  type: 'info',
  content: {
    message: 'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'Got it!',
    deny: 'Refuse cookies',
    link: 'Learn more',
    href: 'https://cookiesandyou.com',
    policy: 'Cookie Policy',
  },
};
@NgModule({
  declarations: [AppComponent, SimpleCenterLoaderComponent, AvatarDirective],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    LayoutModule,
    MatButtonModule,
    MatSnackBarModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    ThemeModule.forRoot({
      themes: THEMES,
      active:
        localStorage.getItem('theme') ||
        (window.matchMedia('(prefers-color-scheme: light)').matches ? 'light' : 'dark'),
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SocketIoModule.forRoot(config),
    MatNativeDateModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    CricketLoaderModule
  ],
  providers: [BackendApiService, GlobalVarsService, BsModalService, IdentityService, ScreenTrackingService,
    DecimalPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
