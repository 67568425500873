import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiProfileModalComponent } from './multi-profile-modal.component';
import { MatDialogModule } from "@angular/material/dialog";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";



@NgModule({
  declarations: [
    MultiProfileModalComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    InfiniteScrollModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    MultiProfileModalComponent
  ]
})
export class MultiProfileModalModule { }
