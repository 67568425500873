import { environment } from '../../environments/environment';

// const API_BASE_URL = environment.csBackendStandalone;
const API_BASE_URL = 'http://localhost:3000/v1/';

// PLAYER PROFILES APIS URL
export const PLAYER_PROFILES_APIS: any = {
  url: API_BASE_URL + 'player-profile',
  list: API_BASE_URL + 'player-profiles',
  search: API_BASE_URL + 'search/player-profiles',
};

//  SPONSOR PROFILES APIS URL
export const SPONSOR_PROFILES_APIS: any = {
  url: API_BASE_URL + 'sponsor-profile',
  list: API_BASE_URL + 'sponsor-profiles',
  all: API_BASE_URL + 'all/sponsor-profiles',
};

//  AUXILIARY_INDIVIDUAL PROFILES APIS URL
export const AUXILIARY_INDIVIDUAL_PROFILES_APIS: any = {
  url: API_BASE_URL + 'auxiliary-individual-profile',
  list: API_BASE_URL + 'auxiliary-individual-profiles',
  all: API_BASE_URL + 'all/auxiliary-individual-profiles',
};

//  USER APIS URL
export const USER_APIS: any = {
  profiles: API_BASE_URL + 'user/profiles/',
  list: API_BASE_URL + 'users',
};

// POSTS APIS URL
export const POSTS_APIS: any = {
  url: API_BASE_URL + 'posts',
};

// TEAMS APIS URL
export const TEAMS_APIS: any = {
  url: API_BASE_URL + 'team-profile',
  search: API_BASE_URL + 'team-profile/search',
  list: API_BASE_URL + 'team-profile/all',
  listAll: API_BASE_URL + 'all/team-profile',
  team: API_BASE_URL + 'team-profile/find/',
  allWithoutPagination: API_BASE_URL + 'team-profiles/eager/all',
};

export const MATCH_INVITE_APIS: any = {
  url: API_BASE_URL + 'team-invite',
  listTeam: API_BASE_URL + 'team-invites/team',
  listFrom: API_BASE_URL + 'team-invites/from',
  listTo: API_BASE_URL + 'team-invites/to',
  listOpenTo: API_BASE_URL + 'team-invites/open/to',
  listOpenFrom: API_BASE_URL + 'team-invites/open',
  listOpenTeam: API_BASE_URL + 'team-invites/open/team',
  matchInvite: API_BASE_URL + 'match-invites',
  recivedMatchInvites: API_BASE_URL + 'match-invites/received/',
  acceptMatchInvites: API_BASE_URL + 'match-invites/accept/',
  rejectMatchInvites: API_BASE_URL + 'match-invites/reject/',
};

export const TEAM_INVITE_APIS: any = {
  url: API_BASE_URL + 'team-invite',
  listTeam: API_BASE_URL + 'team-invites/team',
  listFrom: API_BASE_URL + 'team-invites/from',
  listTo: API_BASE_URL + 'team-invites/to',
  listOpenTo: API_BASE_URL + 'team-invites/open/to',
  listOpenFrom: API_BASE_URL + 'team-invites/open',
  listOpenTeam: API_BASE_URL + 'team-invites/open/team',
};

export const TEAM_EVENT_LOG_APIS: any = {
  url: API_BASE_URL + 'team-logs',
  list: API_BASE_URL + 'team-logs',
};

export const TEAM_MEMBERS_APIS: any = {
  url: API_BASE_URL + 'team-member',
  list: API_BASE_URL + 'team-members',
};

// MATCHES APIS URL
export const MATCHES_APIS: any = {
  url: API_BASE_URL + 'match',
  list: API_BASE_URL + 'matches/all',
  teamMatches: API_BASE_URL + 'matches/team',
  addPlayers: API_BASE_URL + 'match/add-players',
};

// TOURNAMENT APIS URL
export const TOURNAMENT_APIS: any = {
  url: API_BASE_URL + 'tournaments',
};

// GROUNDS APIS URL
export const GROUNDS_APIS: any = {
  url: API_BASE_URL + 'ground',
  list: API_BASE_URL + 'ground/all',
  listAll: API_BASE_URL + 'grounds/eager/all',
  ground: API_BASE_URL + 'grounds/ground/',
};

// QUIZZES APIS URL
export const QUIZZES_APIS: any = {
  url: API_BASE_URL + 'quizzes',
  today: API_BASE_URL + 'today/quizzes/',
};
