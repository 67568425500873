import { Component, OnInit } from '@angular/core';
import { HttpService } from "../../../../services/common/http.service";
import { ChangeDetectorService } from "../../../../services/common/change-detector";
import { CacheServiceService } from "../../../../services/common/cache-service.service";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { MultiProfileModalComponent } from "../../../profile/multi-profile-modal/multi-profile-modal.component";

@Component({
  selector: 'app-user-profiles-modal',
  templateUrl: './user-profiles-modal.component.html',
  styleUrls: ['./user-profiles-modal.component.scss']
})
export class UserProfilesModalComponent implements OnInit {

  user:any;
  constructor( public httpService: HttpService,
               public dialog: MatDialog,
               private changeDetectorService: ChangeDetectorService,
               private cacheServiceService: CacheServiceService,
               private _bottomSheetRef: MatBottomSheetRef<UserProfilesModalComponent>) {
    this.user = this.cacheServiceService.getSessionData('user');
    this.changeDetectorService.watchUserInfoChanges().subscribe(value => {
      this.user = this.cacheServiceService.getSessionData('user');
    })
  }

  ngOnInit(): void {}

  openLink(event: MouseEvent, path, profileTpe, prfileInfo): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
    if(this.user.enableMultiProfiles){
      const ref = this.dialog.open(MultiProfileModalComponent, {
        width: '40%',
        data: {
          user: this.user,
          profileTpe
        },
      });
      ref.afterClosed().subscribe(value => {
        if(value && value.id){
          this.httpService.navigate(path, {id: value.id});
        }
      });
    } else{
      this.httpService.navigate(path, {id: prfileInfo.ID, profileTpe});
    }
  }

  openPersonalProfile(event: MouseEvent, path): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
    this.httpService.navigate(path, {username: this.user.ProfileEntryResponse?.Username});
  }
}
