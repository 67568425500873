import { Component, Input, OnChanges } from '@angular/core';
import { ICONS } from "../../../lookups/icons.lookups";
import { LANGUAGES, THEMES } from "../../../lookups/app.lookups";
import { TranslateService } from "@ngx-translate/core";
import { ThemeService } from "../../../theme/theme.service";
import { HttpService } from "../../../services/common/http.service";
import { CacheServiceService } from "../../../services/common/cache-service.service";
import { ChangeDetectorService } from "../../../services/common/change-detector";
import { GlobalVarsService } from "../../../services/deso/global-vars.service";
import { BackendApiService, ProfileEntryResponse } from "../../../services/deso/backend-api.service";
import { Subscription, zip } from "rxjs";
import { map } from "rxjs/operators";
import { FollowChangeObservableResult } from "../../../../lib/observable-results/follow-change-observable-result";

@Component({
  selector: 'app-profile-cards',
  templateUrl: './profile-cards.component.html',
  styleUrls: ['./profile-cards.component.scss']
})
export class ProfileCardsComponent implements OnChanges{

  @Input() user:any;
  icons:any = ICONS[this.themeService.getActiveTheme().icons];
  themes = THEMES;
  languages = LANGUAGES;
  activeProfile:string;
  loading:boolean;
  followChangeSubscription: Subscription;
  followerCount: number = null;
  followingCount: number = null;
  refreshFollowingBeingCalled = false;
  profile: ProfileEntryResponse;

  constructor(public translate: TranslateService,
              private themeService: ThemeService,
              private backendApi: BackendApiService,
              private globalVars: GlobalVarsService,
              public httpService: HttpService,
              private changeDetectorService: ChangeDetectorService,
              private cacheServiceService: CacheServiceService,) {
    this.changeDetectorService.watchFollowersChanged().subscribe(value => {
      this._refreshContent();
    })
  }


  ngOnChanges(): void {
    this.activeProfile = this.user &&  this.user.playerProfiles && this.user.playerProfiles[0] && this.user.playerProfiles[0].owner ? 'player': 'user';
    this._refreshContent();
  }


  /**
   * CHANGE THE I18 translation
   * @param lang
   */
  public changeLanguage(lang): void {
    this.translate.setDefaultLang(lang);
    this.cacheServiceService.saveLocalStorage('Language', lang);
  }


  /**
   * CHange the theme
   * @param theme
   */
  public changeTheme(theme): void {
    this.themeService.setTheme(theme);
    this.cacheServiceService.saveLocalStorage('Theme', theme);
    this.icons = ICONS[this.themeService.getActiveTheme().icons];
  }


  /**
   * handel switch profile
   * @param profile is the selected profile
   */
  public switchProfile(profile): void {
    this.activeProfile = profile;
  }


  _refreshContent() {
    if(!this.user || !this.user.ProfileEntryResponse || !this.user.ProfileEntryResponse.Username){
      return;
    }
    if (this.loading) {
      return;
    }
/*    let readerPubKey = "";
    if (this.globalVars.loggedInUser) {
      readerPubKey = this.globalVars.loggedInUser.PublicKeyBase58Check;
    }*/
    this.loading = true;
    this.profile = this.user.ProfileEntryResponse;
    // If the user follows/unfollows this user, update the follower count
    this.followChangeSubscription = this.globalVars.followChangeObservable.subscribe((followChangeObservableResult) => {
      this._handleFollowChangeObservableResult(followChangeObservableResult);
    });
    this._refreshFollowing();
    this.loading = false;
    //
    // this.backendApi.GetSingleProfile(this.globalVars.localNode, "", this.user.userName).subscribe(
    //   (res) => {
    //     if (!res || res.IsBlacklisted) {
    //       this.loading = false;
    //       // this.router.navigateByUrl("/" + this.appData.RouteNames.NOT_FOUND, { skipLocationChange: true });
    //       return;
    //     }
    //     this.profile = res.Profile;
    //     // If the user follows/unfollows this user, update the follower count
    //     this.followChangeSubscription = this.globalVars.followChangeObservable.subscribe((followChangeObservableResult) => {
    //       this._handleFollowChangeObservableResult(followChangeObservableResult);
    //     });
    //     this._refreshFollowing();
    //     this.loading = false;
    //   },
    //   (_) => {
    //     this.loading = false;
    //   }
    // );
  }

  _handleFollowChangeObservableResult(followChangeObservableResult: FollowChangeObservableResult) {
    if (followChangeObservableResult.followedPubKeyBase58Check === this.profile.PublicKeyBase58Check) {
      if (followChangeObservableResult.isFollowing) {
        this.followerCount += 1;
      } else {
        this.followerCount -= 1;
      }
    }
  }

  _refreshFollowing() {
    if (this.refreshFollowingBeingCalled) {
      return;
    }

    this.refreshFollowingBeingCalled = true;

    // TODO: need a loading treatment while loading OR need to obtain all follows on pageload

    const getFollowers = this.backendApi
      .GetFollows(
        this.globalVars.localNode,
        this.profile.Username,
        "" /* PublicKeyBase58Check */,
        true /* get followers */,
        "" /* GetEntriesFollowingUsername */,
        0 /* NumToFetch */
      )
      .pipe(map((res) => res.NumFollowers));

    const getFollowing = this.backendApi
      .GetFollows(
        this.globalVars.localNode,
        this.profile.Username,
        "" /* PublicKeyBase58Check */,
        false /* get following */,
        "" /* GetEntriesFollowingUsername */,
        0 /* NumToFetch */
      )
      .pipe(map((res) => res.NumFollowers));

    zip(getFollowers, getFollowing).subscribe(
      (res) => {
        this.followerCount = res[0];
        this.followingCount = res[1];
        this.refreshFollowingBeingCalled = false;
      },
      (error) => {
        this.refreshFollowingBeingCalled = false;
        // fail silently
        console.error(error);
      }
    );
  }

  getFoundersRewardPercent() {
    return this.profile.CoinEntry.CreatorBasisPoints / 100;
  }

}
