import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { HttpService } from "../../../../services/common/http.service";
import { CacheServiceService } from "../../../../services/common/cache-service.service";

@Component({
  selector: 'app-additional-profiles-modal',
  templateUrl: './additional-profiles-modal.component.html',
  styleUrls: ['./additional-profiles-modal.component.scss']
})
export class AdditionalProfilesModalComponent implements OnInit {

  user:any;
  constructor( public httpService: HttpService,
               private cacheServiceService: CacheServiceService,
               private _bottomSheetRef: MatBottomSheetRef<AdditionalProfilesModalComponent>) {
    this.user = this.cacheServiceService.getSessionData('user');
  }

  ngOnInit(): void {}

  openLink(event: MouseEvent, path): void {
     if(!this.user){
       this.httpService.alertWarn('Please Login First');
       this._bottomSheetRef.dismiss();
       event.preventDefault();
       return;
     }
     if(!this.user.enableMultiProfiles && this.user && this.user.playerProfile && this.user.playerProfile.status ==='Active'){
      this.httpService.alertWarn('Please Deactivate Your Player Profile First');
      this._bottomSheetRef.dismiss();
      event.preventDefault();
      return;
     }
     this._bottomSheetRef.dismiss();
     event.preventDefault();
     this.httpService.navigate(path);
  }
}
