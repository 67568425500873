<mat-nav-list>
  <a mat-list-item (click)="openPersonalProfile($event, '../creators/profile')">
    <span mat-line>{{'PersonalProfile' | translate}}</span>
  </a>

  <a mat-list-item (click)="openLink($event, '../profile/player', 'Player',  user.playerProfiles[0])" *ngIf="user && user.playerProfiles && user.playerProfiles.length > 0 ">
    <span mat-line>{{'PlayerProfile' | translate}}</span>
  </a>

  <a mat-list-item (click)="openLink($event, '../profile/sponsor', 'sponsor', user.sponsorProfiles[0])" *ngIf="user && user.sponsorProfiles && user.sponsorProfiles.length > 0 ">
    <span mat-line>{{'SponsorProfile' | translate}}</span>
  </a>

  <a mat-list-item (click)="openLink($event, '../profile/auxiliary', 'auxiliary', user.auxiliaryIndividualProfiles[0])" *ngIf="user && user.auxiliaryIndividualProfiles && user.auxiliaryIndividualProfiles.length > 0">
    <span mat-line>{{'AuxiliaryProfile' | translate}}</span>
  </a>
</mat-nav-list>
