import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from "@ngx-translate/core";

import { LeftSideMenuComponent } from './left-side-menu/left-side-menu.component';
import { RightSideMenuComponent } from './right-side-menu/right-side-menu.component';
import { ProfileCardsModule } from "../common/profile-cards/profile-cards.module";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { AdditionalProfilesModalModule } from "../common/profile-cards/additional-profiles-modal/additional-profiles-modal.module";
import { MatBadgeModule } from "@angular/material/badge";

@NgModule({
  declarations: [
    LeftSideMenuComponent,
    RightSideMenuComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    TranslateModule,
    MatIconModule,
    ProfileCardsModule,
    MatProgressBarModule,
    AdditionalProfilesModalModule,
    MatBadgeModule
  ],
  exports: [
    LeftSideMenuComponent,
    RightSideMenuComponent
  ]
})
export class LayoutModule { }
