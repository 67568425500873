import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  AUXILIARY_INDIVIDUAL_PROFILES_APIS,
  PLAYER_PROFILES_APIS,
  SPONSOR_PROFILES_APIS
} from "../../../lookups/apis.lookups";
import { HttpService, Payload } from "../../../services/common/http.service";
import { DEFAULT_PAYLOAD } from "../../../lookups/app.lookups";

@Component({
  selector: 'app-multi-profile-modal',
  templateUrl: './multi-profile-modal.component.html',
  styleUrls: ['./multi-profile-modal.component.scss']
})
export class MultiProfileModalComponent implements OnInit {

  profile;
  loading;
  profiles:any[] = [];
  payload: Payload = DEFAULT_PAYLOAD;
  currentScrollPage=0;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<MultiProfileModalComponent>,
              private httpService: HttpService) {
    this.profile = this.data.profileTpe
  }

  ngOnInit(): void {
    this.getProfiles();
  }

  getProfiles(): void {
    this.loading = true;
    let URL;
    switch (this.profile) {
      case 'Player': URL = PLAYER_PROFILES_APIS.list + '/' + this.data.user.PublicKeyBase58Check; break;
      case 'auxiliary': URL = AUXILIARY_INDIVIDUAL_PROFILES_APIS.list + '/' + this.data.user.PublicKeyBase58Check; break;
      case 'sponsor': URL = SPONSOR_PROFILES_APIS.list + '/' + this.data.user.PublicKeyBase58Check; break;
    }
    this.httpService.getWithPagination(URL, this.payload).subscribe((value: Payload) => {
      this.payload = value;
      this.currentScrollPage = this.payload.pagination.page;
      this.profiles = this.profiles.concat(this.payload.data || []);
      this.loading = false;
    }, error => {
      this.httpService.alertError('Error While Get Profiles Data', this);
    });
  }

  openProfile(profile): void {
    this.dialogRef.close({id : profile.ID, name : profile.name})
  }
}
