// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  uploadImageHostname: 'node.deso.org',
  verificationEndpointHostname: 'https://node.deso.org',
  uploadVideoHostname: 'https://node.deso.org',
  identityURL: 'https://identity.deso.org',
  supportEmail: '',
  dd: {
    apiKey: 'DCEB26AC8BF47F1D7B4D87440EDCA6',
    jsPath: 'https://bitclout.com/tags.js',
    ajaxListenerPath: 'bitclout.com/api',
    endpoint: 'https://bitclout.com/js/',
  },
  amplitude: {
    key: '',
    domain: '',
  },
  node: {
    id: 1,
    name: 'DeSo',
    url: 'https://love4src.com',
    logoAssetDir: '/assets/deso/',
  },
  postOrigin: 'https://cricket.social',
  socketUrl: 'localhost:3000',
  // csBackendStandalone: "http://localhost:3000/v1/"
  csBackendStandalone: 'https://backend-dev.cricket.social/v1/',
  //csBackendStandalone: "https://cs-backend-standalone-4wdp3hqc4q-uc.a.run.app/v1/"
  firebase: {
    apiKey: 'AIzaSyCU9EJtDxOPolAFA57PbFELOs93hKw1EbQ',
    authDomain: 'cricket-social-dev.firebaseapp.com',
    projectId: 'cricket-social-dev',
    storageBucket: 'cricket-social-dev.appspot.com',
    messagingSenderId: '84748426099',
    appId: '1:84748426099:web:cbfbb862160220956b0306',
    measurementId: 'G-NCLPBW163V',
  },
};
