import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { UserProfilesModalComponent } from "../user-profiles-modal/user-profiles-modal.component";
import { GlobalVarsService } from "../../../../services/deso/global-vars.service";
import { HttpService } from "../../../../services/common/http.service";
import { CommonService } from "../../../../services/common/common.service";

@Component({
  selector: 'app-player-profile-card',
  templateUrl: './player-profile-card.component.html',
  styleUrls: ['./player-profile-card.component.scss']
})
export class PlayerProfileCardComponent {

  @Input() loading:any;
  @Input() playerProfile:any;
  @Input() followerCount:any;
  @Input() followingCount:any;
  @Input() foundersRewardPercent:any;

  @Input() user:any;
  @Input() icons;
  @Input() themes;
  @Input() languages;
  @Output() changeLanguage:EventEmitter<string> = new EventEmitter<any>();
  @Output() changeTheme:EventEmitter<string> = new EventEmitter<any>();
  @Output() switchProfile:EventEmitter<string> = new EventEmitter<any>();

  constructor(private _bottomSheet: MatBottomSheet,
              public httpService: HttpService,
              private commonService: CommonService,
              public globalVars: GlobalVarsService,) { }


  public openUserProfiles(): void {
    if(this.commonService.hasProfiles(this.user)){
      this._bottomSheet.open(UserProfilesModalComponent);
    } else{
      this.httpService.navigate('../creators/profile', {username: this.user.ProfileEntryResponse?.Username});
    }
  }

  totalUSDLocked() {
    return this.user.ProfileEntryResponse ? this.globalVars.abbreviateNumber(
      this.globalVars.nanosToUSDNumber(this.user.ProfileEntryResponse.CoinEntry.DeSoLockedNanos),
      3,
      true
    ): '$0.00 usd';
  }

  totalCoinPrice() {
    return this.user.ProfileEntryResponse ? this.globalVars.nanosToUSD(this.user.ProfileEntryResponse.CoinPriceDeSoNanos, 2): '$0.00 USD'
  }

  coinBalance() {
    return this.user.ProfileEntryResponse ? this.globalVars.nanosToUSD(this.user.BalanceNanos, 2): '$0.00 USD'
  }
}
