import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../../theme/theme.service';
import { CacheServiceService } from '../../../services/common/cache-service.service';
import { ICONS } from '../../../lookups/icons.lookups';
import { HttpService } from '../../../services/common/http.service';
import { ChangeDetectorService } from '../../../services/common/change-detector';
import { AdditionalProfilesModalComponent } from '../../common/profile-cards/additional-profiles-modal/additional-profiles-modal.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { GlobalVarsService } from 'src/app/services/deso/global-vars.service';

@Component({
  selector: 'app-left-side-menu',
  templateUrl: './left-side-menu.component.html',
  styleUrls: ['./left-side-menu.component.scss'],
})
export class LeftSideMenuComponent implements OnInit {
  moreVisible: boolean;
  loading: boolean;

  user: any;
  icons: any = ICONS[this.themeService.getActiveTheme().icons];
  menuItems: any[] = [
    { title: 'Home', route: '../home', icon: 'home' },
    { title: 'Discover', route: '../creators', icon: 'people' },
    { title: 'Notifications', route: '../notifications', icon: 'notification' },
    { title: 'Buy_DESO', route: '../deso/buy', icon: 'crypto' },
    { title: 'My Wallet', route: '/wallet', icon: 'wallet' },
    { title: 'Messages', route: '../inbox', icon: 'message' },
    // {title: 'Chat', route: '', icon: this.icons.chat},
    { title: 'More', route: '', icon: 'more' },
  ];

  moreMenuItems: any[] = [
    // { title: 'Quizzes', route: '../quizzes', icon: 'dao', type: 'internal' },
    { title: 'DAO', route: 'https://cric.so/dao', icon: 'dao', type: 'external' },
    { title: 'Fierce_Cricketing_Cubs', route: 'https://cric.so/fcc', icon: 'fcc', type: 'external' },
    { title: 'Cricket_Social_Blog', route: 'https://cric.so/blog', icon: 'csBlog', type: 'external' },
    { title: 'Help', route: 'mailto:support@cricket.social', icon: 'help', type: 'mail' },
  ];
  subMenuItems: any[] = [
    { title: 'Players', route: '../players', icon: 'players' },
    { title: 'Teams', route: '../teams', icon: 'teams' },
    // { title: 'Invites', route: '../team-invites', icon: 'teams' },
    { title: 'Grounds', route: '../grounds', icon: 'grounds' },
    { title: 'Matches', route: '../wip', icon: 'home' },
    { title: 'Tournaments', route: '../wip', icon: 'tournaments' },
    { title: 'Others', route: '../other', icon: 'players' },
  ];

  constructor(
    public translate: TranslateService,
    private themeService: ThemeService,
    public httpService: HttpService,
    private _bottomSheet: MatBottomSheet,
    private changeDetectorService: ChangeDetectorService,
    private cacheServiceService: CacheServiceService,
    private globalVars: GlobalVarsService
  ) {
    this.user = this.cacheServiceService.getSessionData('user');
    this.changeDetectorService.watchUserInfoChanges().subscribe((value) => {
      this.user = this.cacheServiceService.getSessionData('user');
    });
  }
  ngOnInit(): void {}

  public createTeamTournaments(): void {
    /* if(!this.user){
      this.httpService.alertWarn('Please Login First');
      return;
    }*/
    this.httpService.navigate('../tournaments/tournament');
  }

  public openMenuItem(name, path): void {
    if (name === 'More') {
      this.moreVisible = !this.moreVisible;
    } else {
      this.httpService.navigate(path || '../home');
    }
  }

  public get APP_ICONS() {
    return ICONS.light;
  }

  public createAdditionalProfile(): void {
    this._bottomSheet.open(AdditionalProfilesModalComponent);
  }
}
