<div class="userCard fadeIn">
  <img
    [src]="commonService.getUserAvatar(user.ProfileEntryResponse.PublicKeyBase58Check)"
    alt=""
    class="centeredBox profileImg"
  />
  <div class="centerContainer statistics">
    <div style="cursor: pointer" (click)="httpService.navigate('../followers', {userName: user.ProfileEntryResponse?.Username, tabName: 'followers'})">
      <span>{{ followerCount || 0 }}</span>
      <span>{{ "Followers" | translate }}</span>
    </div>
    <div style="cursor: pointer" (click)="httpService.navigate('../followers', {userName: user.ProfileEntryResponse?.Username, tabName: 'following'})">
      <span>{{ followingCount || 0 }}</span>
      <span>{{ "Following" | translate }}</span>
    </div>
    <div>
      <span>{{ foundersRewardPercent || 0 }}%</span>
      <span>FR</span>
    </div>
  </div>
  <div class="centerContainer userCardActions">
    <button mat-raised-button color="primary" class="roundedBtn primaryBtn" (click)="openUserProfiles()">
      <img [src]="icons.profile" alt="" />
      {{ "Profile" | translate }}
    </button>
    <button mat-raised-button color="primary" class="roundedBtn lightBtn" [matMenuTriggerFor]="settingsMenu">
      <img [src]="icons.settings" alt="" />
      {{ "Settings" | translate }}
    </button>

    <mat-menu #settingsMenu="matMenu">
      <button mat-menu-item (click)="httpService.navigate('../update-profile')">
        <img [src]="icons.profile2" class="iconImg-sm" alt="" />
        {{ "Profile" | translate }}
      </button>
      <!-- <button mat-menu-item  [matMenuTriggerFor]="themeMenu">
        <img [src]="icons.theme" class="iconImg-sm" alt=""/> {{'Theme' | translate}}
      </button>
      <mat-menu #themeMenu="matMenu">
        <button mat-menu-item *ngFor="let theme of themes" (click)="changeTheme.emit(theme.key)">{{('themes.'+theme.key) | translate}}</button>
      </mat-menu>
      <button mat-menu-item  [matMenuTriggerFor]="languageMenu">
        <img [src]="icons.language"  class="iconImg-sm" alt=""/>
        {{'Language' | translate}}
      </button>
      <mat-menu #languageMenu="matMenu">
        <button mat-menu-item *ngFor="let language of languages"
                (click)="changeLanguage.emit(language.lang)"> {{language.name | translate}}</button>
      </mat-menu> -->
      <button mat-menu-item (click)="httpService.launchLogoutFlow()">
        <img [src]="icons.logout" alt="" />
        {{ "Logout" | translate }}
      </button>
    </mat-menu>
  </div>
  <div class="roundedContainer hashContainer noShadow">
    <div class="roundedContainerTitle centerContainer mainBtn">
      <div>
        <img [src]="icons.key" alt="" />
      </div>
      <div class="cut-text">{{ user.PublicKeyBase58Check }}</div>
      <button mat-icon-button aria-label="copyHashBtn" [cdkCopyToClipboard]="user.PublicKeyBase58Check">
        <img [src]="icons.copy" alt="" />
      </button>
    </div>
  </div>
  <div class="coinSection">
    <div>
      <span>{{ "CoinPrice" | translate }}</span>
      <span>{{ totalCoinPrice() }}</span>
    </div>
    <div>
      <span>{{ "CoinBalance" | translate }}</span>
      <span>{{ coinBalance() }}</span>
    </div>
    <div>
      <span>{{ "DESOBalance" | translate }}</span>
      <span>{{ totalUSDLocked() }}</span>
    </div>
  </div>

  <button
    mat-button
    class="switchProfileBtn"
    (click)="switchProfile.emit('player')"
    *ngIf="user.playerProfiles && user.playerProfiles[0] && user.playerProfiles[0].owner"
  >
    <img [src]="icons.switch" class="iconImg-sm" alt="" />
    {{ "Switch_player_Profile" | translate }}
  </button>
</div>
