import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdditionalProfilesModalComponent } from "./additional-profiles-modal.component";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";



@NgModule({
  declarations: [
    AdditionalProfilesModalComponent
  ],
  imports: [
    CommonModule,
    MatBottomSheetModule,
    MatListModule,
    MatButtonModule,
    TranslateModule
  ],
  exports: [AdditionalProfilesModalComponent]
})
export class AdditionalProfilesModalModule { }
