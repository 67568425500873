<mat-nav-list>
  <a mat-list-item (click)="openLink($event, '../profile/player')" *ngIf="!user.playerProfiles || user.playerProfiles.length === 0 || user.enableMultiProfiles">
    <span mat-line>{{'CreatePlayerProfile' | translate}}</span>
  </a>

  <a mat-list-item (click)="openLink($event, '../profile/sponsor')" *ngIf="!user.sponsorProfiles || user.sponsorProfiles.length === 0  || user.enableMultiProfiles">
    <span mat-line>{{'CreateSponsorProfile' | translate}}</span>
  </a>

  <a mat-list-item (click)="openLink($event, '../profile/auxiliary')" *ngIf="!user.auxiliaryIndividualProfiles || user.auxiliaryIndividualProfiles.length === 0 || user.enableMultiProfiles">
    <span mat-line>{{'CreateAuxiliaryProfile' | translate}}</span>
  </a>
</mat-nav-list>
