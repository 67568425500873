<div class="userCard fadeIn">

  <div class="userCardViewUser">
    <img [src]="icons.photo2" alt=""/>
    <div>
      <span>Ronald Richards</span>
      <span>@RonaldRichards</span>
      <div class="userBalance">
        <span>$1,234.56</span>
        <button mat-raised-button color="primary" class="roundedBtn mainBtn balanceBtn">{{'Buy' | translate}}</button>
      </div>
    </div>
  </div>

  <div class="centerContainer statistics">
    <div>
      <span>{{followerCount || 0}}</span><span>{{'Followers' | translate}}</span>
    </div>
    <div>
      <span>{{followingCount || 0}}</span><span>{{'Following' | translate}}</span>
    </div>
    <div>
      <span>{{foundersRewardPercent || 0}}%</span><span>FR</span>
    </div>
  </div>
  <div class="centerContainer userCardActions">
    <div class="playerTeam">
      <img [src]="'assets/icons/flags/india.png'" alt=""/>
      <span class="spacer"></span>
      <p>
        {{'TEAM' | translate}} <br/>
        DELHI CAPITALS
      </p>
    </div>
    <button mat-raised-button color="primary" class="roundedBtn lightBtn">
      {{'View' | translate}}
    </button>
  </div>
  <div class="coinSection">
    <div><span>{{'BATTING_STYLE' | translate}}</span><span>Right Handed Bat</span></div>
    <div><span>{{'BOWLING_STYLE' | translate}}</span><span>Right Hand</span></div>
    <div><span>{{'WICKETKEEPER' | translate}}</span><span>Yes</span></div>
  </div>
  <div class="centerContainer userCardActions">
    <button mat-raised-button color="primary" class="roundedBtn lightBtn" (click)="switchProfile.emit('user')">
      {{'View_Profile' | translate}}
    </button>
    <button mat-raised-button color="primary" class="roundedBtn primaryBtn">
      {{'Follow' | translate}}
    </button>
  </div>
</div>
