import { Payload } from '../services/common/http.service';

export const APP_CASH_KEY = 'CRICKET_';

export const LANGUAGES: any[] = [
  { name: 'English', lang: 'en' },
  { name: 'Hindi', lang: 'in' },
];

export const THEMES: any[] = [
  { key: 'light', name: 'Light Theme', icons: 'dark' },
  { key: 'dark', name: 'Dark Theme', icons: 'light' },
];

export enum ACTIONS {
  SAVE = 'SAVE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export const GOOGLE = {
  MAPS_KEY: 'AIzaSyB0jgXip9JGY91rY8YRiGl9VK4Iz3Nzw7g',
  POLYGON_OPTIONS: {
    drawingControl: true,
    drawingControlOptions: {
      drawingModes: ['polygon'],
    },
    polygonOptions: {
      draggable: false,
      editable: false,
    },
  },
};

export const ARMS_LOOKUP: string[] = ['Right', 'Left', 'Ambidextrous'];
export const SKILLS_LOOKUP: string[] = ['Bowling', 'Batting', 'All-Rounde'];
export const GENDER_LOOKUP: any[] = [
  { name: 'Male', value: 'M' },
  { name: 'Female', value: 'F' },
  { name: 'Other', value: 'O' },
];

export const DEFAULT_PAYLOAD: Payload = {
  pagination: {
    next: 1,
    page: 1,
    perPage: 30,
    prev: 0,
    total: 0,
    totalPage: 0,
  },
  data: [],
};
