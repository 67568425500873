<div class="modal-content">
  <div class="modalHeader">
    {{profile | translate}} {{'profiles' | translate}}
    <button mat-icon-button>
      <mat-icon mat-dialog-close>close</mat-icon>
    </button>
  </div>
  <div class="modalBody"
       infinite-scroll
       [infiniteScrollDistance]="0"
       [infiniteScrollUpDistance]="0"
       [infiniteScrollThrottle]="300"
       [scrollWindow]="false"
       (scrolled)="getProfiles()">
    <div class="simpleProfileCard" (click)="openProfile(profile)" *ngFor="let profile of profiles">
      <img [src]="profile.profilePic" class="player-img" alt="" />
      <div class="simpleProfileCardHeader">
        <span>{{profile.name}}</span>
      </div>
      <span class="spacer"></span>
    </div>
    <div class="spinner-container" *ngIf="loading">
      <mat-spinner [diameter]="70"></mat-spinner>
      <p> {{'Loading..' | translate}}</p>
    </div>
  </div>
</div>
