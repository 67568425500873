import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { UserProfilesModalComponent } from "../user-profiles-modal/user-profiles-modal.component";

@Component({
  selector: 'app-user-view-card',
  templateUrl: './user-view-card.component.html',
  styleUrls: ['./user-view-card.component.scss']
})
export class UserViewCardComponent{

  @Input() loading:any;
  @Input() followerCount:any;
  @Input() followingCount:any;
  @Input() foundersRewardPercent:any;

  @Input() user:any;
  @Input() icons;
  @Input() themes;
  @Input() languages;
  @Output() changeLanguage:EventEmitter<string> = new EventEmitter<any>();
  @Output() changeTheme:EventEmitter<string> = new EventEmitter<any>();
  @Output() switchProfile:EventEmitter<string> = new EventEmitter<any>();
  constructor(private _bottomSheet: MatBottomSheet) { }

  public openUserProfiles(): void {
    this._bottomSheet.open(UserProfilesModalComponent);
  }
}
