import { Injectable } from '@angular/core';
import { Socket } from "ngx-socket-io";

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(private socket: Socket) {
    this.socket.connect()
  }


  // emit event
  fetchTeamMembers() {
    this.socket.emit('');
  }

  newTeamMember() {
    const someData = {ms: 'hello'};
    this.socket.emit('some:event', JSON.stringify(someData), function(data){
      console.log('ACK from server wtih data: ', data);
    });
  }

  // listen event
  OnFetchTeamMembers() {
    this.socket.emit('notice', "d")
    this.socket.on('reply', (data) =>{
      console.log('------------------- socket event fetchTeamMembers data ------------');
      console.log(data)
    });
    this.socket.on('notice', (data) =>{
      console.log('------------------- socket event fetchTeamMembers data ------------');
      console.log(data)
    });
  }
}
