<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div class="userSectionHeader" *ngIf="user">
  <div class="loggedInUser">
    <span>{{ "Hi" | translate }} {{ user.ProfileEntryResponse?.Username }}</span>
    <span>{{ "WelcomeBack" | translate }}</span>
  </div>
  <span class="spacer"></span>
  <div class="position-relative">
    <button mat-icon-button aria-label="notificationBtn" (click)="httpService.navigate('../team-invites')">
      <img [src]="icons.notification" alt="" />
    </button>
    <span
      class="openInvitesCount d-flex align-items-center justify-content-center position-absolute"
      *ngIf="openInvitesCount > 0"
    >
      {{ openInvitesCount }}
    </span>
  </div>
  <button mat-icon-button aria-label="messageBtn" (click)="httpService.navigate('../inbox')">
    <img [src]="icons.message" alt="" />
  </button>
</div>

<app-profile-cards [user]="user" *ngIf="user"></app-profile-cards>

<div class="roundedContainer noShadow">
  <div class="roundedContainerTitle active">
    <span>{{ "learnMore" | translate }}</span>
    <button
      mat-raised-button
      color="primary"
      (click)="httpService.navigate('../landing')"
      class="roundedBtn lightBtn"
      style="padding: 0 40px 0 20px"
    >
      {{ "ClickHere" | translate }}
    </button>
  </div>
</div>
