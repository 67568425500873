import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CacheServiceService} from './cache-service.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Observable } from "rxjs";
import { ChangeDetectorService } from "./change-detector";
import { GlobalVarsService } from "../deso/global-vars.service";
import { BackendApiService } from "../deso/backend-api.service";
import { IdentityService } from "../deso/identity.service";
import { filter, get } from "lodash";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  httpHeaders: any = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
  };
  params: any;
   constructor(private http: HttpClient,
               public globalVars: GlobalVarsService,
               private backendApi: BackendApiService,
               private identityService: IdentityService,
               private _snackBar: MatSnackBar,
               private cache: CacheServiceService,
               private changeDetectorService: ChangeDetectorService,
               private router: Router,
               private route: ActivatedRoute) {
     this.route.queryParams.subscribe((params: any) => {
       this.params = params;
     });
   }
   get routerParams(): any {return this.params; }
   get routerQueryParams(): Observable<Params> {return this.route.queryParams; }
   get routerPathParams(): Observable<any> {return this.route.params; }

    // -------------------- http --------------------
    getWithPagination(path, payload: Payload, params: any = {}) {
      params.page = payload.pagination.next;
      params.limit = payload.pagination.perPage;
      this.createAuthorizationHeader();
      return this.http.get<any>(path, { headers: new HttpHeaders(this.httpHeaders), params: {...params }});
    }

    getRequest(path, params: any = {}) {
      this.createAuthorizationHeader();
      return this.http.get<any>(path, { headers: new HttpHeaders(this.httpHeaders), params: {...params }});
    }
    postRequest(path, body, params: any = {}) {
      this.createAuthorizationHeader();
      return this.http.post(path, body, {headers: new HttpHeaders(this.httpHeaders), params: {...params}});
    }
    putResource(path, body, params: any = {}) {
      this.createAuthorizationHeader();
      return this.http.put(path, body, {headers: new HttpHeaders(this.httpHeaders), params: {...params}});
    }
    deleteResource(path, params: any = {}) {
      this.createAuthorizationHeader();
      return this.http.delete(path, {headers: new HttpHeaders(this.httpHeaders), params: {...params}});
    }


    // ------------- navigation -------------
    navigate(path, params = {}){this.router.navigate([path], { queryParams: params}); }


    // ------------------ alerts ------------------
   alert( message, componentRef?, duration?): void {
     if ( !message){ return; }
     this._snackBar.open(message, 'Splash', {
       horizontalPosition: 'start',
       verticalPosition: 'bottom',
       duration: duration ? 20000 : 90000,
       panelClass: ['red-snackbar']
     });
     if ( componentRef ) {
       componentRef.loading = false;
     }
   }

  alertWarn( message, componentRef?): void {
    if ( !message){ return; }
    this._snackBar.open(message, 'close',
      {horizontalPosition: 'center', verticalPosition: 'bottom', duration: 20000, panelClass: ['app-snackbar', 'warn-snackbar']});
    if ( componentRef ) {
      componentRef.loading = false;
      componentRef.actionLoading = false;
    }
  }

  alertReminder( message): void {
    if ( !message){ return; }
    this._snackBar.open(message, 'close',
      {horizontalPosition: 'right', verticalPosition: 'bottom', duration: 20000, panelClass: ['app-snackbar', 'warn-snackbar', 'reminderAlert']});
  }

  alertError( message, componentRef?): void {
    if ( !message){ return; }
    this._snackBar.open(message, 'close',
      {horizontalPosition: 'center', verticalPosition: 'bottom', duration: 20000, panelClass: ['app-snackbar', 'error-snackbar']});
    if ( componentRef ) {
      componentRef.loading = false;
      componentRef.actionLoading = false;
    }
  }
  alertSuccess( message, componentRef?): void {
    if ( !message){ return; }
    this._snackBar.open(message, 'close',
      {horizontalPosition: 'center', verticalPosition: 'bottom', duration: 20000, panelClass: ['app-snackbar', 'success-snackbar']});
    if ( componentRef ) {
      componentRef.loading = false;
      componentRef.actionLoading = false;
    }
  }


  /**
   * construct authorization headers
   * @param httpHeaders httpHeaders
   */
  createAuthorizationHeader(httpHeaders?): void {
    const token: string = this.cache.getSessionData('user') ? this.cache.getSessionData('user').userToken : null;
    if ( token ) {
      this.httpHeaders.Authorization =  token;
    }
  }

  // ------------------ logout ---------------
  logOut(): void{
    this.cache.clearSession();
    this.cache.removeLocalStorage('user');
    this.changeDetectorService.emitUserInfoChanges();
    this.navigate('../../landing');
  }

  public launchLogoutFlow() {
    if(!this.globalVars.loggedInUser){
      this.logOut();
      return;
    }
    const publicKey = this.globalVars.loggedInUser.PublicKeyBase58Check;
    this.identityService.launch("/logout", { publicKey }).subscribe((res) => {
      this.globalVars.userList = filter(this.globalVars.userList, (user) => {
        this.logOut();
        return res?.users && user?.PublicKeyBase58Check in res?.users;
      });
      if (!res?.users) {
        this.globalVars.userList = [];
      }
      let loggedInUser = get(Object.keys(res?.users), "[0]");
      if (this.globalVars.userList.length === 0) {
        loggedInUser = null;
        this.globalVars.setLoggedInUser(null);
      }
      this.backendApi.setIdentityServiceUsers(res.users, loggedInUser);
      this.globalVars.updateEverything().add(() => {
        if (!this.globalVars.userInTutorial(this.globalVars.loggedInUser)) {
          // this.httpService.navigate(["/" + this.globalVars.RouteNames.BROWSE]);
          this.logOut();
        }
      });
      this.logOut();
    });
  }


  getProfile(profiles:any[], id: string): any{
    let profileInfo:any = null;
    for ( let i=0; i < profiles.length; i++){
      if(profiles[i].ID === id){
        profileInfo = {};
        profileInfo.index = i;
        profileInfo.profile = profiles[i];
      }
    }
    return profileInfo;
  }
}

export interface Pagination {
  next: number;
  page: number;
  perPage: number;
  prev: number;
  total: number;
  totalPage: number;
}

export interface Payload {
  pagination: Pagination,
  data: any[]
}
