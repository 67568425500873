import { Injectable } from '@angular/core';
import {APP_CASH_KEY} from '../../lookups/app.lookups';

@Injectable({
  providedIn: 'root'
})
export class CacheServiceService {

    constructor() {}

    // -------------------- local storage ------------------
    saveLocalStorage = (key, value) => localStorage.setItem(APP_CASH_KEY + key, JSON.stringify(value));
    removeLocalStorage = (key) => localStorage.removeItem(APP_CASH_KEY + key);
    getLocalStorage = (key): any => JSON.parse(localStorage.getItem(APP_CASH_KEY + key));


    // -------------------- session storage ------------------
    setSessionData = (key, data) =>  sessionStorage.setItem(APP_CASH_KEY + key, JSON.stringify(data));
    getSessionData = (key): any => JSON.parse(sessionStorage.getItem(APP_CASH_KEY + key));
    deleteSessionData = (key) => sessionStorage.removeItem(APP_CASH_KEY + key);
    clearSession = () => sessionStorage.clear();
}
