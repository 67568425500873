import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CacheServiceService } from '../cache-service.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileCheckGuard implements CanActivate {
  constructor(private cache: CacheServiceService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let user = this.cache.getSessionData('user');
    if (user.ProfileEntryResponse === null) {
      this.router.navigate(['../../update-profile']);
      return false;
    } else {
      return true;
    }
  }
}
